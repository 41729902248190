import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Markdown from 'react-markdown';

import styles from './disclaimers.module.scss';
const Disclaimers = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiDisclaimersPage {
        nodes {
          disclaimers
          title
        }
      }
    }
  `);

  const { disclaimers, title } = query.allStrapiDisclaimersPage.nodes[0];
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{title}</h2>
      <hr className={styles.line} />
      <div className={styles.content}>
        <Markdown source={disclaimers} escapeHtml={false} />
      </div>
    </div>
  );
};

export default Disclaimers;
